<template>
  <section v-if="show" class="section --clients">
    <h2 class="title" data-aos="fade-up">{{ getCustomersSectionData.title }}</h2>

    <p class="subtitle" data-aos="fade-up">
      {{ getCustomersSectionData.subtitle }}
    </p>
    <div class="clients">
      <div
        v-for="(client, index) in getCustomersSectionData.clients"
        :key="`${client.id}__${index}`"
        class="client"
        data-aos="fade-up"
      >
        <img
          width="156"
          height="156"
          v-lazy-load :data-src="client.clients.client.logo.mediaItemUrl"
          alt=""
          role="presentation"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AOS from 'aos'
export default {
  name: 'AppClientSection',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getCustomersSectionData() {
      return this.$store.getters.getCustomersSectionData
    },
  },
  mounted() {
    AOS.refresh()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/components/common/sections/AppClientsSection';
</style>
